// import { renderToString } from 'react-dom/server';
import { slug } from 'github-slugger';
import type { ReactElement } from 'react';

export const hexToRGB = (hexString: string) => {
  const hex = hexString.length === 7 ? hexString.substring(1).match(/.{1,2}/g) : hexString.match(/.{1,2}/g);
  const rgbArray = hex && [parseInt(hex[0], 16), parseInt(hex[1], 16), parseInt(hex[2], 16)];
  if (!rgbArray) {
    throw new Error('You must provide a valid hex string');
  }

  return rgbArray as [number, number, number];
};

export const stringToKebabCase = (id: string) =>
  id
    .toLowerCase()
    .replace(/[^A-Za-z0-9 ]/g, '')
    .trim()
    .replace(/\s+/g, '-');

/**
 * @param string any string, including ones already in camelCase
 * @returns the string in camelCase form
 */
export const toCamelCase = (string: string) =>
  string.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_m, chr) => chr.toUpperCase());

/**
 * @param string background color, ex: indigo-500
 * @returns boolean
 */
export const isBackgroundDark = (string: string) => {
  if (string && string !== '') {
    const strArr = string.split('-');
    if (parseInt(strArr[strArr.length - 1]) >= 400) {
      return true;
    }
  }

  return false;
};

/**
 * @param dateStr any string
 * @returns formatted Date
 */
export const formatDate = (dateStr: string) => {
  const tmp = new Date(dateStr);
  let date = '';
  if (tmp.getDate() < 10) {
    date = '0' + tmp.getDate();
  } else {
    date = tmp.getDate().toString();
  }

  return tmp.toLocaleString('en-us', { month: 'short' }) + ' ' + date + ', ' + tmp.getFullYear();
};

export const getPlainText = (node: ReactElement) => {
  // const plainText = renderToString(node);
  const plainText = JSON.stringify(node);

  return plainText.replace(/<[^>]+>/gi, '');
};

export const getIdFromNode = (node: ReactElement | string) => {
  if (typeof node === 'string') {
    return slug(node);
  } else {
    return slug(getPlainText(node));
  }
};

/**
 * @param text any text string
 * @returns reading time
 */
export const getReadingTime = (text: string) => {
  const wpm = 225;
  const words = text?.trim()?.split(/\s+/)?.length;
  const time = Math.ceil(words / wpm);

  return time;
};

export const validateEmail = (email: string) => {
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (email.match(validRegex)) {
    return true;
  }

  return false;
};

export function getSectionIdForLinkOrButton(element: Node & Element) {
  let clickedElement: (Node & Element) | null = element;
  let closestSectionId: string | null = null;

  // Traverse up the DOM tree from the clicked element to find the closest section element
  while (clickedElement && clickedElement.tagName !== 'SECTION') {
    clickedElement = clickedElement.parentNode as Node & Element;
  }

  // If a section element was found, get its ID
  if (clickedElement && clickedElement.tagName === 'SECTION') {
    closestSectionId = clickedElement.getAttribute('id');
  }

  return closestSectionId;
}

export function getTableOfContentsFromRTE(content: { nodeType: string; data: {}; content: any }[]) {
  const toc = [] as any[];

  content.forEach(node => {
    const depth = parseInt(node.nodeType.split('heading-')[1]);
    if (node.nodeType.includes('heading') && depth < 4) {
      toc.push({ ...node, id: getIdFromNode(node.content[0].value) });
    }
  });

  return toc;
}

import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

interface AdjustImageDimensionsProps {
  baseWidth: number;
  scaleFactor: number;
  width: number;
  height: number;
}
export const adjustImageDimensions = function (props: AdjustImageDimensionsProps) {
  const { baseWidth, scaleFactor, width, height } = props;
  const imageRatio = width / height;
  const newWidth = Math.ceil(Math.pow(imageRatio, scaleFactor) * baseWidth);
  const newHeight = Math.ceil(newWidth / imageRatio);

  return { width: newWidth, height: newHeight };
};

export function randomIntInRange(min: number, max: number) {
  return Math.floor(Math.random() * (max - min) + min);
}
 